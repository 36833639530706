import { defineStore } from 'pinia';
import { uuidv7 } from 'uuidv7';
import { deleteImage } from '@/services/media.service';
import { uploadImage } from '@/services/media.service';

export const useMemorialProfileStore = defineStore('memorialProfile', {
    state: () => ({
        categoryConfig: {
            education: {
                startYear: '',
                endYear: '',
                place: '',
                description: '',
                photoUrl: '',
            },
            work: {
                startYear: '',
                endYear: '',
                place: '',
                description: '',
                photoUrl: '',
            },
            interests: {
                category: '',
                name: '',
                description: '',
                photoUrl: '',
            },
            family: {
                name: '',
                relation: '',
            },
            gallery: {
                items: [],
            },
            achievements: {
                day: '',
                month: '',
                year: '',
                title: '',
                description: '',
                photoUrl: '',
            },
            important_events: {
                day: '',
                month: '',
                year: '',
                title: '',
                description: '',
                photoUrl: '',
            },
            additional_description: {
                day: '',
                month: '',
                year: '',
                title: '',
                description: '',
                photoUrl: '',
            },
        },
        _memorialProfile: {
            slot_number: '',
            isPrivate: false,
            firstName: '',
            secondName: '',
            lastName: '',
            familyName: '',
            birthDate: {
                day: '',
                month: '',
                year: '',
            },
            birthPlace: '',
            deathDate: {
                day: '',
                month: '',
                year: '',
            },
            deathPlace: '',
            mainPhotoUrl: '',
            sections: [],
        },
    }),

    getters: {
        memorialProfile: (state) => state._memorialProfile,
    },
    actions: {
        updateProfileState(profileData) {
            Object.assign(this._memorialProfile, profileData);
        },
        addSectionState(category) {
            const item = this.getCategoryItemConfig(category);

            const newSection = {
                id: uuidv7(),
                key: category,
                items: [item],
            };

            this._memorialProfile.sections.push(newSection);
        },
        removeSectionState(sectionId) {
            const section = this._memorialProfile.sections.find((section) => section.id === sectionId);
            const sectionItems = section.items;

            sectionItems.forEach((item) => {
                this.deleteImage({ photoUrl: item.photoUrl, categoryName: section.key, sectionItemId: item.id });
            });

            this._memorialProfile.sections = this._memorialProfile.sections.filter(
                (section) => section.id !== sectionId
            );
        },
        addSectionItemState(category) {
            const section = this._memorialProfile.sections.find(
                (s) => s.key === category
            );

            const newItem = this.getCategoryItemConfig(category);

            if (section) {
                section.items.push(newItem);
            }
        },
        removeSectionItemState(sectionId, itemId) {
            const section = this._memorialProfile.sections.find(
                (s) => s.id === sectionId
            );

            if (section) {
                const item = section.items.find((item) => item.id === itemId);
                this.deleteImage({ photoUrl: item.photoUrl, categoryName: section.key, sectionItemId: itemId });

                section.items = section.items.filter((item) => item.id !== itemId)
            }
        },
        updateSectionItemState(sectionId = null, itemId, itemData) {
            const section = sectionId 
                ? this._memorialProfile.sections.find((s) => s.id === sectionId)
                : this._memorialProfile.sections.find((s) => s.items.some((item) => item.id === itemId));

            if (section) {
                const itemIndex = section.items.findIndex((item) => item.id === itemId);

                if (itemIndex !== -1) {
                    section.items[itemIndex] = { ...section.items[itemIndex], ...itemData };
                }
            }
        },
        getCategoryItemConfig(categoryKey) {
            const config = this.categoryConfig[categoryKey];

            return {
                id: uuidv7(),
                ...config,
            };
        },
        updateGalleryImagesState(imagePaths) {
            const gallerySection = this._memorialProfile.sections.find(
                (section) => section.key === 'gallery'
            );

            if (gallerySection) {
                gallerySection.items = imagePaths.map((path) => ({ photoUrl: String(path) }));
            }
        },
        async uploadImage({ file, categoryName, sectionItemId }) {
            try {
                if (!file) {
                    throw new Error('No file provided');
                }

                sectionItemId = sectionItemId ? sectionItemId.toString() : ''

                const data = await uploadImage(file, categoryName, sectionItemId);

                if (categoryName === 'personal_details') {
                    this._memorialProfile.mainPhotoUrl = data.file_path;
                    return;
                }

                const section = this._memorialProfile.sections.find(s => s.key === categoryName);

                if (!section) {
                    return;
                }

                if (sectionItemId) {
                    const item = section.items.find(item => item.id === sectionItemId);

                    if (item) {
                        item.photoUrl = data.file_path;
                    }
                } else if (section.items.length > 0) {
                    section.items[0].photoUrl = data.file_path;
                }
            } catch (error) {
                console.error('Error uploading image:', error);
                throw error;
            }
        },
        async deleteImage({ photoUrl = '', categoryName, sectionItemId }) {
            try {
                if (photoUrl) {
                    await deleteImage(photoUrl);
                }

                sectionItemId = sectionItemId ? sectionItemId.toString() : '';

                if (categoryName === 'personal_details') {
                    this._memorialProfile.mainPhotoUrl = '';
                } else {
                    this.updateSectionItemState(null, sectionItemId, { photoUrl: '' });
                }
            } catch (error) {
                console.error('Error deleting image:', error);
                throw error;
            }
        },
    },
});
