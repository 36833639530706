import {createApp} from 'vue';
import VueCookies from 'vue3-cookies';
import axios from 'axios';
import App from './App.vue';
import router from './router';
import 'vuetify/styles';
import {createVuetify} from 'vuetify';
import * as components from 'vuetify/lib/components';
import * as directives from 'vuetify/lib/directives';
import '@mdi/font/css/materialdesignicons.css';
import 'swiper/css/bundle';
import { createPinia } from 'pinia';

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.common['Authorization'] = 'Bearer <token>'; // Ensure you replace <token> with actual logic to retrieve the token

const vuetify = createVuetify({
  components,
  directives,
});
const pinia = createPinia();
const app = createApp(App);

app.use(pinia);
app.use(router);
app.use(VueCookies);
app.use(vuetify).mount('#app');
