// filename: src/views/ContactPage.vue
<template>
  <div class="contact-image mobile"></div>

  <div class="static-page">
    <div class="content contact-page">
      <h1 class="static-page-header">Kontakt</h1>

      <div class="contact-details-wrapper">
        <div class="contact-image"></div>

        <p class="static-page-paragraph contact-details">
          <span class="contact-box">
            <span class="label"><strong>Gdzie i jak kupić produkt TakŻyli?</strong></span>
            <span>
              wypełnij <a class="link" target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSe8ccQoQ-whUi-izldFe0_z-0Qx5ECXz05AOxn4kpsAd4JaKA/viewform">FORMULARZ</a><br>
              lub napisz na <a class="link" href="mailto:sprzedaz@takzyli.pl">sprzedaz@takzyli.pl</a>
            </span>
          </span>

          <span class="contact-box">
            <span class="label">W celu zapytań o współpracę:</span>
            <span><a class="link" href="mailto:b2b@takzyli.pl">b2b@takzyli.pl</a></span>
          </span>

          <span class="contact-box">
            <span class="label">Wszystkie inne pytania:</span>
            <span><a class="link" href="mailto:biuro@takzyli.pl">biuro@takzyli.pl</a></span>
          </span>

          <span>Zapraszamy do kontaktu!</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactPage',
};
</script>

<style lang="scss" scoped>
.link {
  color: #A28546;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: color 0.3s ease, border 0.3s ease;

  &:hover {
    border-bottom: 1px solid #A28546;
  }
}

.static-page {
  margin-top: 44px;
  display: flex;
  background-color: #FFFFFF;
  border-radius: 16px;
  flex-direction: column;
  align-items: center;

  .content {
    width: 100%;
    height: fit-content;
    max-width: 1296px;
    padding: 16px;

    @media (min-width: 540px) {
      padding: 88px;
    }
  }

  .static-page-header {
    font-family: 'Source Serif 4', serif;
    margin-bottom: 24px;
    font-style: italic;

    @media (min-width: 768px) {
      margin-bottom: 32px;
    }
  }

  .static-page-paragraph {
    line-height: 150%;
    letter-spacing: 0.5px;
    font-size: 1rem;
    margin-bottom: 16px;
    text-align: justify;
    font-family: 'Source Serif 4', serif;

    @media (min-width: 768px) {
      font-size: 1.25rem;
      letter-spacing: 0.1px;
    }
  }
}

.contact-page {
  align-items: flex-start;
}

.contact-details-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    gap: 44px;
  }
}

.company-name {
  font-weight: 600;
  text-align: left;
  margin-bottom: 2px;

  @media (min-width: 768px) {
    text-align: justify;
    margin-bottom: 4px;
  }
}

.contact-details {
  display: flex;
  flex-direction: column;
  line-height: 150%;
  letter-spacing: 0.5px;
  gap: 16px;

  @media (min-width: 768px) {
    gap: 24px;
  }
}

.contact-image {
  display: none;
  background: url('../assets/images/contact-image-400x400.png') no-repeat center;
  background-size: cover;

  @media (min-width: 768px) {
    display: flex;
    width: 420px;
    height: 420px;
  }

  &.mobile {
    display: flex;
    height: 100px;
    width: 100%;

    @media (min-width: 768px) {
      display: none;
    }
  }
}

.contact-box {
  display: flex;
  flex-direction: column;
  font-family: 'Source Serif 4', serif;

  .label {
    font-weight: 500;
  }
}
</style>
