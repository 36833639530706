// filename: src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes';
import authService from '@/services/auth.service'; // authService is a centralized auth service

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // Set the document title based on the current route
  document.title = to.meta.title || 'Tak Żyli';

  if (authService.requiresAuth(to) && !authService.isTokenValid()) {
    authService.logout();
    next({ name: 'login' });
  } else {
    if (to.name === 'next') {
      next();
    } else {
      next();
    }
  }
});

export default router;
