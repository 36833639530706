// filename: src/services/authService.js
import router from "@/router";
import { ROUTE_NAMES } from "@/router/routes";
import { reactive } from 'vue';

const TOKEN_KEY = 'userToken';

const authState = reactive({
  isLoggedIn: isTokenValid(),
});

function updateAuthStatus() {
  authState.isLoggedIn = isTokenValid();
}

function getToken() {
  return localStorage.getItem(TOKEN_KEY);
}

function setToken(token) {
  localStorage.setItem(TOKEN_KEY, token);
  updateAuthStatus();
  setAutoLogout();
}

function removeToken() {
  localStorage.removeItem(TOKEN_KEY);
  updateAuthStatus();
}

function isTokenValid() {
  const token = getToken();

  if (token) {
    try {
      const decodedToken = JSON.parse(atob(token.split('.')[1]));
      return decodedToken && (decodedToken.exp * 1000) > new Date().getTime();
    } catch (error) {
      console.error('Failed to decode or validate token', error);
      return false;
    }
  }
  return false;
}

function logout() {
  removeToken();
  router.push({ name: ROUTE_NAMES.LOGIN });
}

function requiresAuth(to) {
  return to.matched.some(record => record.meta.requiresAuth);
}

// Set a timeout to auto logout when the token expires
function setAutoLogout() {
  const token = getToken();

  if (token) {
    const decodedToken = JSON.parse(atob(token.split('.')[1]));
    const expiresIn = (decodedToken.exp * 1000) - new Date().getTime();

    setTimeout(logout, expiresIn);
  }
}

export default {
  getToken,
  setToken,
  removeToken,
  isTokenValid,
  logout,
  requiresAuth,
  setAutoLogout,
  authState,
  updateAuthStatus,
};
